import React, { useContext, useEffect, useState } from "react"
import { navigate } from "gatsby"
import "slick-carousel/slick/slick.css"

import "../../styles/pages/contact-lenses.scss"
import SEO from "../../components/seo"
import Layout from "../../components/layout"
import CartContext from "../../context/cartContext"
import ProductSlider from "../../components/ProductSlider"
import VideoModule from "../../components/VideoModule"
import ReviewCarousel from "../../components/ReviewCarousel"
import HowHubbleWorksCarousel from "../../components/HowHubbleWorksCarousel"
import AboutProductSection from "../../components/AboutProductSection"
import Breadcrumbs from "../../components/Breadcrumbs"
import ProductValueProps from "../../components/ProductValueProps"
import {
  colorTags,
  contactsFaq,
  hubbleValueProps,
  hubbleDifference,
  classicAdvantages,
  getHubblePricing,
  hubbleClassic,
} from "../../services/data"
import ProductWithHubble from "../../components/ProductWithHubble"
import FAQBlock from "../../components/FAQBlock"
import ProductDetails from "../../components/ProductDetails"
import OrderFlow from "../../components/OrderFlow"
import NewButton from "../../components/NewButton"
import RelatedFrames from "../../components/RelatedFrames"
import { StaticImage } from "gatsby-plugin-image"
import ProductSectionAdvantages from "../../components/ProductSectionAdvantages"
import useNewRxFlow from "../../utils/useNewRxFlow"
import ContactsDetailsMobile from "../../components/ContactsDetailsMobile"
import NewRxFlow from "../../components/NewRxFlow"
import useNewRxFlowDesktop from "../../utils/useNewRxFlowDesktop";
import useAbTest  from '../../utils/useAbTest'
import avgProducts from "../../services/avg-products.json";
import {hubbleList} from "../../components/ContactsPrescription/mockedData";
import {productInfo} from "../../components/Intake/Prescription/mockedData";
import usePromo from '../../utils/usePromo'
import Banner from '../../components/Banner'
import useIsLifemart from "../../components/Lifemart/hooks/useIsLifemart";
import useIsMilitary from "../../components/Military/hooks/useIsMilitary";


const HubbleLenses = () => {
  const isNewRx = useNewRxFlow()
  const isNewRxDesktop = useNewRxFlowDesktop()
  const isPaidShipping = useAbTest()
  const isPromo = usePromo ()
  const isLifemart = useIsLifemart()
  const isMilitary = useIsMilitary()
  const hubblePricing = getHubblePricing()


  const [slides, setSlides] = useState([
      "Pages/ContactsPDP/Hubble-Classic-Lens-01.png",
      "Pages/ContactsPDP/Hubble-Classic-Lens-02.jpg",
      "Pages/ContactsPDP/Hubble-Classic-Lens-03.png",
      "Pages/ContactsPDP/Hubble-Classic-Lens-04.jpg",
      "Pages/ContactsPDP/Hubble-Classic-Lens-05.png",
  ])
  const bulletPointsNoFda = [
    "Single vision soft contact lenses made from methafilcon A hydrogel material",
    "Have 55% water content",
    "UV protection contact lenses",
    "Designed for easy insertion, all-day comfort, and crystal-clear vision",
    `${isPaidShipping ? "The starter pack for daily contacts is only $1, plus shipping." :"The $1 starter pack for daily contacts has no hidden fees or charges"}`,
  ]

  const bulletPointsFda = "FDA-approved"

  const bulletPoints = [(isNewRx || isNewRxDesktop) ? null : bulletPointsFda, ...bulletPointsNoFda].filter(Boolean)

  const [show, setShow] = useState(false)

  const { cartItems, createOrder, setHeaderColor, clearOrder, setIsHydro, currentItem } = useContext(
    CartContext
  )


  const hubble_lenses = cartItems.filter(obj => {
    return obj.type === "contacts"
  })

  const item = {
    title: "Classic Daily Contact Lenses By Hubble",
    subheader: "Order discount contact lenses online easily with Hubble. Personalize your subscription and receive our affordable Classic by Hubble lenses delivered directly to your door.",
    price: !isLifemart && !isMilitary ? 22.99 : 19.99,
    offPrice: 1.0,
    baseCurve: 8.6,
    diameter: 14.2,
    material: "Methafilcon A Hydrogel",
    manufacturer: "St. Shine Optical Co",
    id: 1,
  }

  const type = "contacts"

  useEffect(() => {
    if (typeof window !== 'undefined') {
      sessionStorage.removeItem("previousPage")
      const urlParams = new URLSearchParams(window?.location.search)
      const rxflow = urlParams.get('rxflow')
      const newRxFlow = urlParams.get('newRxFlow')
      if (rxflow === "true" || newRxFlow === "true") {
        setShow(true)
      }


      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push({
        event: "view_item",
        ecommerce: {
          items: [
            {
              item_id: 2167100637257,
              item_name: 'Hubble Classic Lenses',
              currency: "USD",
              discount: 0,
              item_brand: "Hubble",
              item_category: "contacts",
              item_category2: 'spherical',
              item_category3: 'N/A',
              item_category4: 'Methafilcon A',
              item_category5: 'N/A',
              item_list_id: `Contacts`,
              item_variant: 19809451933769,
              price: 1.00,
              quantity: 1,
              index: 0
            }
          ]
        }
      })
    }

    setIsHydro(false)
  }, [])

  useEffect(() => {
    const newItem = {
      id: '',
      type
    }

    const newOrder = {
      email: "",
      state: "",
      doctor: "",
      prescription: {
        rightEye: {
          power: "",
          baseCurve: "8.6",
          diameter: "14.2",
        },
        leftEye: {
          power: "",
          baseCurve: "8.6",
          diameter: "14.2",
        },
      },
      isOneEye: false,
    }
    setHeaderColor("purple")

    if (!isNewRx && !currentItem) createOrder(newItem, newOrder)
  }, [cartItems, isNewRx])

  useEffect(() => {
    return () => {
      setHeaderColor("black")
      // if (!isNewRx && !currentItem) clearOrder()
    }
  }, [])

  const relatedProducts = [
    {
      productImage1: "https://cdn.shopify.com/s/files/1/1516/5228/products/Avenova-Eye-Compress-PCP-01.png",
      price: 19.99,
      title: "Avenova Eye Compress",
      handle: "avenova-eye-compress",
      type: 'accessory',
    },
    {
      productImage1: "https://cdn.shopify.com/s/files/1/1516/5228/products/Biotrue-Hydration-Boost-33oz-PCP-01.png",
      price: 12.99,
      title: "Biotrue Hydration Boost Eye Drops",
      handle: "biotrue-hydration-boost-new",
      type: 'accessory',
    },
    {
      productImage1: "https://cdn.shopify.com/s/files/1/1516/5228/products/Alaway-.34oz-PCP-01.png",
      price: 12.99,
      title: "Alaway",
      handle: "alaway",
      type: 'accessory',
    },
    {
      productImage1: "https://cdn.shopify.com/s/files/1/1516/5228/products/Ocuvue-Eye-Health-PCP-01.png",
      price: 11.99,
      title: "Ocuvite Eye Health",
      handle: "ocuvite-eye-health",
      type: 'accessory',
    },
  ]

  const schemaMarkup = [
    {
      "@context" : "https://schema.org/",
      "@type" : "Product",
      "name" : "Classic Contact Lenses by Hubble",
      "image" : "/static/a62bcead85a05fe142f3ceb0af9a6670/a18a4/Hubble-Classic-Lens-01.webp",
      "description" : `Get your first ${hubbleClassic.trialPeriodPairs} pairs of prescription contacts for just $1; after that, a 28-day supply is just $19.99 per eye. Delivered right to your door every month.`,
      "Brand":
      {
      "@type" : "Thing",
      "name" : "Hubble"
      },
      "Logo" : "https://cdn.shopify.com/s/files/1/1516/5228/t/3/assets/full_logo_red.svg?v=14192704095517354305",
      "Offers":
      [
      {
      "@type" : "Offer",
      "url " :"https://hubblecontacts.com/contact-lenses/classic-by-hubble-lenses/",
      "availability" : "https://schema.org/InStock",
      "Price":"1.00",
      "priceCurrency":"USD",
      "priceValidUntil":"2024-12-31"
      }
      ]
      ,"reviews":
      [
      {"@type":"Review","author":"Zulema J.","description":"I’m glad I switched to Hubble. I put in a fresh pair every day. Never have to worry about reordering. So crisp and comfortable!!!","reviewRating":{"@type":"Rating","ratingValue":"5"}},
      {"@type":"Review","author":"Jessi H.","description":"This is my second month using Hubble and I’ll never go back! They are comfortable, affordable, and so healthy for my eyes!",
      "reviewRating":{"@type":"Rating","ratingValue":"5"}},
      {"@type":"Review","author":"Charlie K.","description":"Omg! When I put them on I was extremely impressed. I’m in love. I cannot even remotely tell they are in. I will be a forever subscriber.","reviewRating":{"@type":"Rating","ratingValue":"5"}},
      {"@type":"Review","author":"Anna H.","description":"This is literally the only brand of contacts that works for me. I’ve tried all of the major brands and was ready to give up. I love my Hubble contacts <3.","reviewRating":{"@type":"Rating","ratingValue":"5"}}
      ]
    },
    {
      "@context": "https://schema.org",
      "@type": "VideoObject",
      "name": "Hubble Contacts How We Make Ordering Affordable Contact Lenses Online Easy",
      "description": "At Hubble, we believe ordering discount contact lenses online should be easy. That’s why we’ve made Hubble’s personalized contact lens subscription service the easiest contacts experience in the world. You can easily order cheap contacts in your prescription online. Affordable daily contact lenses that are always shipped for free! Order contacts online and start your subscription for only 1 dollar",
    "thumbnailUrl": "https://i.ytimg.com/vi/w4YCLxsUxyI/hqdefault.jpg",
      "uploadDate": "2023-03-20",
      "duration": "PT1M15S",
      "contentUrl": "https://www.hubblecontacts.com",
      "embedUrl": "https://www.youtube.com/embed/w4YCLxsUxyI"
    },
    {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": [
        {
          "@type": "Question",
          "name": "What are your Hubble contact lenses made of?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Our affordable daily contact lenses are made of methafilcon-A hydrogel material with 55% water content, UV protection, and a thin edge."
          }
        },
        {
          "@type": "Question",
          "name": "Are Hubble contact lenses FDA-approved?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Yes, our Hubble discounted contact lenses, the Classic by Hubble contact lenses, have been FDA approved for sale in the US for over a decade. And they are available starting at only one dollar for the starter pack. Order these cheap contacts online and get them delivered free!"
          }
        },
        {
          "@type": "Question",
          "name": "Can teenagers wear Hubble contact lenses?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Yes, all patients ages 13+, who have a valid contact lens prescription, can wear Hubble daily lenses. Please note: patients under the age of eighteen must receive parental permission before placing an order for contact lenses online with Hubble."
          }
        },
        {
          "@type": "Question",
          "name": "Does Hubble sell bi-weekly or monthly contact lenses?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "We only sell daily lenses because wearing a new lens every day is the safest and most comfortable way to wear contacts."
          }
        },
        {
          "@type": "Question",
          "name": "Can I use water or contact lens solution to clean or store my Hubble contact lenses?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "No! Hubble lenses are daily contact lenses, designed for individual use. Please do not attempt to wear them for more than one day.  For as low as one dollar for the new client starter pack, the Classic by Hubble contact lenses are affordable!"
          }
        },
        {
          "@type": "Question",
          "name": "Do the Hubble contact lenses come with an eye exam?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "No, the Classic Lenses by Hubble do not include professional services such as an eye exam. But you can take our vision test online when renewing your affordable contacts prescription."
          }
        }
      ]
    },
    {
      "@context" : "https://schema.org",
      "@url" : "https://hubblecontacts.com/contact-lenses/classic-by-hubble-lenses/",
      "@type" : "webpage",
      "name" : "Hubble Contacts Discount Classic Lenses: Try for $1 and Start Your Contacts Subscription",
      "description" : "Order your cheap contacts online and Hubble ships your contact lenses automatically. Get affordable daily disposable contact lenses delivered with free shipping. New users can start their subscription for $1! Contacts for only a dollar shipped directly to you.",
      "publisher" :
      {
      "@type" : "Organization",
      "name" : "Hubble"
      }
    }
  ]

  const handleGaClick = () => {
    if (isNewRx || isNewRxDesktop) {
      navigate(`/contact-lenses/classic-by-hubble-lenses/?newRxFlow=true`)
    }

    setShow(true)
    if (typeof window !== "undefined") {
      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push({
        event: "select_item",
        ecommerce: {
          items: [
            {
              item_id: 2167100637257,
              item_name: 'Hubble Classic Lenses',
              currency: "USD",
              discount: 0,
              item_brand: "Hubble",
              item_category: "contacts",
              item_category2: 'spherical',
              item_category3: 'N/A',
              item_category4: 'Methafilcon A',
              item_category5: 'N/A',
              item_list_id: `Contacts`,
              item_variant: 19809451933769,
              price: 1.00,
              quantity: 1,
              index: 0
            }
          ]
        }
      });
    }
  }

  const validateBrandPrescription = (item, prescription) => {
    const hubbleBrand =
      item.title.toLowerCase().includes("hubble classic") && "default" ||
      item.title.toLowerCase().includes("skyhy by") && "skyhy" ||
      item.title.toLowerCase().includes("hydro by") && "hydro"
    const prescriptionSettings = {...productInfo[hubbleBrand || item.cc_product_id]};
    prescriptionSettings.eye_powers = [
      { value: 'oneEye', label: `` },
      ...prescriptionSettings.eye_powers
    ]
    const eyes = [prescription.leftEye, prescription.rightEye]

    if (eyes[0].power === "oneEye" && eyes[1].power === "oneEye") return false;

    return eyes.every(eye => {
      if (eye.power === "oneEye") return true;

      return Object.entries(eye).every(([key, value]) => {
        let customKeys = {
          "power": "eye_powers",
          "addPower": "add_power",
          "baseCurve": "base_curve_values"
        }

        let _key = customKeys[key] || key;

        if (key === "diameter" || key === "baseCurve" && (
            !Array.isArray(prescriptionSettings[_key]) ||
            Array.isArray(prescriptionSettings[_key]) && prescriptionSettings[_key].length < 2)
        )
          return true

        if (typeof prescriptionSettings[_key] === "object")
          if (typeof prescriptionSettings[_key][0] === "object") {
            return prescriptionSettings[_key].some(({value: settingsValue}) => settingsValue == value)
          } else {
            return prescriptionSettings[_key].some((settingsValue) => settingsValue == value)
          }
        else
          return prescriptionSettings[_key] == value
      }) && Object.keys(prescriptionSettings).every(key => {
        let customKeys = {
          "eye_powers": "power",
          "add_power": "addPower",
          "base_curve_values": "baseCurve"
        }

        let _key = customKeys[key] || key;

        return !!eye[_key]
      })
    })
  }

  return (
    <Layout className="hubble-lenses">
      <SEO
        title="Order Discount Daily Contact Lenses Online: Classic Lenses | Hubble Contacts"
        description="Shop for discount prescription contacts online with Hubble. Easily personalize your daily lens subscription and receive our affordable Classic by Hubble lenses delivered right to your door."
        schemaMarkup={schemaMarkup} />
        {
        isPromo &&
        <Banner
          bannerClass="purple homepage-v2-banner discount-banner promo-banner pcp extra-padding"
          bannerText={{
            mobile: <><b>Limited Time Only</b> — Start SkyHy or Hydro by Hubble for up to 80% Off.<br/><span class='cta-button-banner'>Shop Now</span></>,
            desktop: <><b>Limited Time Only</b> — Start SkyHy or Hydro by Hubble for up to 80% Off. <span class='cta-button-banner'>Shop Now</span></>,
          }}
          buttonLink={"/contact-lenses/hubble"}
          clickable
        />
      }
      {isNewRx ?
        <ContactsDetailsMobile item={item} type={type} slides={slides} handleGaClick={handleGaClick} lenses={hubble_lenses} /> :
        <div className="top-section">
          <NewButton
            label={"Start Your Subscription for $1"}
            color={"purple"}
            onClick={() => handleGaClick()}
            disabled={hubble_lenses?.length !== 0}
            className={'mobile-only'}
            stickyScroll={true} />
          <ProductSlider slides={slides} product={item} type={type} containImage centerImage />

          <ProductDetails item={item} type={type} onClick={() => handleGaClick()} />
        </div>}
      <ProductValueProps props={hubbleValueProps()} type={type} />
      <div className="product-info">
        <div className="grid-section">
          <Breadcrumbs
            links={[
              { to: "/", label: "Homepage" },
              { to: "/contact-lenses/hubble", label: "Hubble Contact Lenses" },
              { label: "Classic Daily Contact Lenses by Hubble" },
            ]}
          />
        </div>
        {!isNewRx && <>
          <AboutProductSection
            title="Hubble Lenses"
            subtitle={'Convenient discount contact lenses'}
            type={type}
            description={`Affordable contact lenses delivered when and where you need them.`}
            subheader={`Hubble’s best-in-class, personalized contact lens subscription service
            is the easiest way to get affordable, daily wear contacts right to your door every month.
            Our mission is simple — to be the best place to buy contacts online.`}
            bullet_points={bulletPoints}
            />
          <ProductSectionAdvantages list={classicAdvantages} />
        </>}

        <HowHubbleWorksCarousel color={colorTags[type]} />
        <div className="grid-section lifestyle-shot">
          <div className="desktop-lifestyle">
            <StaticImage
              src="../../images/Pages/ContactsPDP/Hubble-Contacts-PDP-Inset-Image.jpg"
              alt="Cheerful woman putting in her Hubble contacts"
            />
          </div>
          <div className="mobile-lifestyle">
            <StaticImage
              src="../../images/Pages/ContactsPDP/Hubble-Contacts-PDP-Inset-Image-Mobile.jpg"
              alt="Cheerful woman putting in her Hubble contacts"
            />
          </div>
        </div>
        <ProductWithHubble type={type} items={hubbleDifference} />
        <VideoModule
          type={type}
          video={"https://www.youtube.com/embed/NKxNYzCRGwg"}
          image="Pages/ContactsPDP/Hubble-Contacts-PDP-Video-Still.jpg"
          alt_text="Woman unboxing a new delivery of Hubble Contacts"
        />
        {isNewRx &&
          <AboutProductSection
            title="Hubble Lenses"
            subtitle={'Convenient discount contact lenses'}
            type={type}
            description={`Affordable contact lenses delivered when and where you need them.`}
            subheader={`Hubble’s best-in-class, personalized contact lens subscription service
            is the easiest way to get affordable, daily wear contacts right to your door every month.
            Our mission is simple — to be the best place to buy contacts online.`}
            bullet_points={bulletPoints}
          />}
          {isNewRx &&
            <>
              <div id="reviews"></div>
              <ReviewCarousel color={colorTags[type]} page="contacts" />
            </>
          }
        <FAQBlock faqList={contactsFaq} color={colorTags[type]} className={`classic-lenses ${isNewRx ? 'noBottomPadding' : ''}`}/>
        {!isNewRx && <NewButton
          label={"Start Your Subscription for $1"}
          color={"purple"}
          onClick={() => handleGaClick()}
          disabled={hubble_lenses?.length !== 0}
          className={'below-faq'}
        />}
        {!isNewRx &&
          <>
            <div id="reviews"></div>
            <ReviewCarousel color={colorTags[type]} page="contacts" />
          </>
        }
      </div>
      {isNewRx || isNewRxDesktop ?
        <NewRxFlow type={type} show={show} setShow={setShow} brand='classic' onHide={() => setShow(false)}
                   validateBrandPrescription={validateBrandPrescription}
                   optionsList={[{options: [...avgProducts.flatMap(e => e.options), {
                       id: -1,
                       title: "Browse 50+ more brands at contactscart.com",
                       price: "",
                       value: -1,
                       link: "https://www.contactscart.com/",
                     }, ...hubbleList(hubblePricing)]}]}/> :
        <OrderFlow type={type} show={show} setShow={setShow} brand='classic' />}
      <RelatedFrames
        relatedFrames={relatedProducts}
        type={type}
        linkTo="/contact-lenses"
      />
    </Layout>
  )
}
export default HubbleLenses
